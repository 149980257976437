
import { Options, Vue } from 'vue-class-component';
import AppHome from './components/AppHome.vue';

@Options({
  components: {
   AppHome,
  },
})
export default class App extends Vue {}
