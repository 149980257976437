
import { NForm, NInput, NButton, NInputGroup, NTable } from 'naive-ui'
import gql from 'graphql-tag'
import graphqlClient from '../utils/graphql'
import { ref } from 'vue';

export default {
    name: 'AppHome',
    components: {
      NForm, NInput, NButton, NInputGroup, NTable
    },
    props: { 
        queryValue: String
    },
    async setup(/*props : any, { emit } : any*/) {
        let bomSearchObj : BomSearch = {
            bomSearch: {
                serialNumber: '',
                version: '',
                componentVersion: '',
                componentGroup: '',
                componentName: '',
                singleQuery: ''
            }
        }
        let boms = ref(await searchBom(bomSearchObj))

        const headers = [
            {text: 'Bom Version', value: 'bomversion'}, 
            {text: 'Group', value: 'group'},
            {text: 'Name', value: 'name'}, 
            {text: 'Component Version', value: 'version'},
            {text: 'Actions', value: 'actions'}
        ]

        const bomsTest = [
            {
                bomversion: '1',
                group: '2',
                name: '3',
                version: '4',
                actions: '5'
            }
        ]

        const searchQuery = ref('')
        async function userSearch (e:any) {
            e.preventDefault()
            bomSearchObj = {
                bomSearch: {
                    serialNumber: '',
                    version: '',
                    componentVersion: '',
                    componentGroup: '',
                    componentName: '',
                    singleQuery: searchQuery.value
                }
            }
            boms.value = await searchBom(bomSearchObj)
        }

        return {
            boms,
            bomsTest,
            headers,
            userSearch,
            searchQuery
        }
    }
}

type BomSearch = {
  bomSearch: {
    serialNumber: string,
    version: string,
    componentVersion: string,
    componentGroup: string,
    componentName: string,
    singleQuery: string
  }
}

async function searchBom(bomSearch: BomSearch) {
    const response = await graphqlClient.query({
        query: gql`
            query findBom ($bomSearch: BomSearch) {
                findBom(bomSearch: $bomSearch) {
                    uuid
                    meta
                    version
                    bomVersion
                    group
                    name
                }
            }`,
        variables: bomSearch,
        fetchPolicy: 'no-cache'
    })
    return response.data.findBom
}

